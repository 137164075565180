.textarea-line-green {
  font-family: "Inter", serif;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: #053f2e 2px solid;
  letter-spacing: 0.2px;
  padding: 12px 16px;
  height: 160px;
  outline: none;
  transition: all 0.2s ease-in-out;
  resize: none;
}

.textarea-line-green:focus {
  box-shadow: 0 0 0 2px #10855c;
  outline: none;
  transition: all 0.2s ease-in-out;
}

.textarea-bg-white {
  font-family: "Inter", serif;
  background-color: #f8f7ee;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  border: #f8f7ee 2px solid;
  letter-spacing: 0.2px;
  padding: 12px 16px;
  height: 160px;
  outline: none;
  transition: all 0.2s ease-in-out;
  resize: none;
}

.textarea-bg-white:focus {
  box-shadow: 0 0 0 2px #10855c;
  outline: none;
  transition: all 0.2s ease-in-out;
}