.btn-white {
  color: #053f2e;
  background-color: #f2efdd;
}

.btn-white:hover {
  color: #f2efdd;
  border-color: #f2efdd;
  background-color: transparent;
}

.btn-yellow {
  color: #053f2e;
  background-color: #f3ff03;
}

.btn-yellow:hover {
  color: #f3ff03;
  border-color: #f3ff03;
  background-color: transparent;
}

.btn-green {
  color: #f2efdd;
  background-color: #053f2e;
}

.btn-green:hover {
  color: #053f2e;
  border-color: #053f2e;
  background-color: transparent;
}

.btn-line {
  color: #053f2e;
  border-color: #053f2e;
  background-color: transparent;
}

.btn-line:hover {
  color: #f2efdd;
  background-color: #053f2e;
}

.btn-greenY {
  color: #f3ff03;
  border-color: #053f2e;
  background-color: #053f2e;
}

.btn-greenY:hover {
  color: #053f2e;
  background-color: transparent;
}

.btn-line-white {
  color: #f2efdd;
  background-color: transparent;
  border-color: #f2efdd;
}

.btn-line-white:hover {
  color: #053f2e;
  background-color: #f2efdd;
}

.btn-line-red {
  color: #EB4B4B;
  background-color: transparent;
  border-color: #EB4B4B;
}

.btn-line-red:hover {
  color: #f2efdd;
  background-color: #EB4B4B;
}