.bg-intro {
  background-image: url("../public/images/app/bg-intro.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-comming-soon {
  background-image: url("../public/images/app/bg-comming.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-coaching {
  background-image: url("../public/images/coaching/bg_pc.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-success {
  background-image: url("../public/images/coaching/bg-succes.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-plansuscription {
  background-image: url("../public/images/notiwero/bg-plans.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.bg-notiwero {
  background-image: url("../public/images/notiwero/bg-noti.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-success {
  background-image: url("../public/images/notiwero/bg-success.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.bg-linktree{
  background-image: url("../public/images/notiwero/bg-noti.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;

}

.bg-home{
  background-image: url("../public/images/home/bg-home.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
}

.bg-about{
  background-image: url("../public/images/about/gifabout.gif");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
}

.bg-about::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

/* Swiper Home */

.swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-next:after {
  content: "" !important;
}

/* Modal */
.ReactModal__Overlay {
  z-index: 999;
  background-color: transparent !important;
}
