@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&family=Kaushan+Script&family=Noto+Serif:wght@100;200;300;400;500;600;700;800;900&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oregano:ital@0;1&family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap');


@font-face {
    font-family: 'Brushaff';
    src: url('../src/assets/fonts/Brushaff.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

html {
    scroll-behavior: smooth;
}

h1{
    font-family: 'Noto Serif', serif;
    font-size: 100px;
    line-height: 136px;
    font-weight: 300;
    color: #343434;
}


h2 {
    font-family: 'Noto Serif', serif;
    font-size: 76px;
    line-height: 103px;
    font-weight: 300;
    color: #343434;
}

h3{
    font-family: 'Noto Serif', serif;
    font-size: 64px;
    line-height: 87px;
    font-weight: 300;
    color: #343434;
    letter-spacing: -0.96px;
}

h4{
    font-family: 'Noto Serif', serif;
    font-size: 40px;
    line-height: 54px;
    font-weight: 300;
    color: #343434;
    letter-spacing: -1.5px;
}

p{
    font-family: 'Work Sans', serif;
    font-size: 22px;
    line-height: 25px;
    font-weight: 400;
    color: #343434;
}

li{
    font-family: 'Work Sans', serif;
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
}

label {
    font-family: 'Work Sans', serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: #272E35;
}

input {
    font-family: 'Work Sans', serif;
    background-color: transparent;
    font-size: 15px;
    font-weight: 500;
    border-radius: 12px;
    padding:0 16px;
    height: 42px; 
    outline: none;
    transition: all 0.2s ease-in-out;
}
textarea {
    font-family: 'Work Sans', serif;
    font-size: 15px;
    font-weight: 500;
    border-radius: 12px;
    padding:12px 16px;
    height: 201px; 
    outline: none;
    transition: all 0.2s ease-in-out;
    resize: none;
}

button, a {
    font-family: 'Work Sans', serif;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    height: 46px;
    border-radius: 12px;
    border: 2px solid transparent; 
    transition: border-color 0.3s ease, background-color 0.3s ease;
    box-sizing: border-box;
}

@media (max-width: 1024px){
    h3 {
        font-size: 40px;
        line-height: 68px;
    }

    h4{
        font-size: 30px;
        line-height: 34px;
    }
    
}

@media (max-width: 768px) {
    
    h2 {
        font-size: 50px;
        line-height: 68px;
    }
    h3 {
        font-size: 38px;
        line-height: 54px;
    }

    h4 {
        font-size: 28px;
        line-height: 38px;
    }

    p {
        font-size: 20px;
        line-height: 23px;
    }
    
    li {
        font-size: 20px;
        line-height: 23px;
    }

    input{
        font-size: 14px;
        height: 38px;
    }

    textarea{
        font-size: 14px;
        height: 169px; 

    }
}